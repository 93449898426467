// @ts-strict-ignore
import { createContext, useContext } from "react";

import type { DropdownInternals } from "../types";

const DropdownContext = createContext<DropdownInternals>(null);

const useDropdownContext = (): DropdownInternals => useContext(DropdownContext);

export { useDropdownContext, DropdownContext };
