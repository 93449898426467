import type { MutableRefObject } from "react";

import { useIsomorphicLayoutEffect } from "@shared/hooks/useIsomorphicLayoutEffect/useIsomorphicLayoutEffect";

const useOutsideClick = <T extends HTMLElement>(
  ref: MutableRefObject<T>,
  onClick: (e: MouseEvent) => void
) => {
  useIsomorphicLayoutEffect(() => {
    const handleClick = (e: MouseEvent) => {
      if (ref?.current && !ref.current.contains(e.target as Node)) {
        onClick(e);
      }
    };
    document.addEventListener("click", handleClick, { capture: true });

    return () => {
      document.removeEventListener("click", handleClick, { capture: true });
    };
  }, [onClick]);
};

export { useOutsideClick };
