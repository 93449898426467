// @ts-strict-ignore
import type { IconDefinition } from "@fortawesome/fontawesome-common-types";
import type { HTMLProps, KeyboardEvent, ReactNode, SyntheticEvent } from "react";

import { FontAwesomeIcon } from "@shared/react-fontawesome";

import { useDropdownContext } from "../DropdownContext";

import * as styles from "./DropdownItem.styles";

interface Props extends HTMLProps<HTMLDivElement> {
  /**
   * Sets implementation of boilerplate styles
   */
  noStyle?: boolean;
  /**
   * Sets if item is currently active
   */
  isHighlighted?: boolean;
  /**
   * Hides the spacer for month selector
   */
  hideSpacer?: boolean;
  /**
   * Callback fires when component is selected
   */
  onSelect?: (e?: SyntheticEvent) => void;
  /**
   *  If true, the Dropdown will close when this item is selected.
   *  If defined, will override Dropdown component's closeOnItemSelect prop
   */
  closeOnItemSelect?: boolean;
  /**
   * Sets text justification
   *
   * @default right
   */
  textDirection?: "left" | "right";
  /**
   * sets if item can be selected
   */
  disabled?: boolean;
  /**
   * sets color theme
   *
   * @default "default"
   */
  isColor?: "default" | "danger";
  /**
   * An icon to be rendered at the end of the dropdown item
   */
  icon?: IconDefinition;
  /**
   * Customizes the location of the icon
   *
   * @default end
   */
  iconLocation?: "start" | "end";
  /**
   * Removes the width truncation text limit
   *
   */
  noTruncation?: boolean;
  /**
   * Show text by multi-line if it's long
   *
   */
  showByMultiLine?: boolean;
  /**
   * Content to render within the dropdown item
   *
   * @default undefined
   */
  children?: ReactNode;
}

const DropdownItem = ({
  children,
  closeOnItemSelect,
  disabled,
  isColor = "default",
  isHighlighted,
  hideSpacer,
  noStyle,
  onSelect,
  textDirection = "left",
  icon,
  iconLocation = "end",
  noTruncation,
  showByMultiLine = false,
  ...rest
}: Props) => {
  const { onItemSelected, setItemFocused, isFullWidth } = useDropdownContext();

  const getTabIndex = () => (disabled ? -1 : 0);

  const clickHandler = (e?: SyntheticEvent) => {
    selectHandler(e);
  };

  const keyPressHandler = (event: KeyboardEvent<HTMLElement>) => {
    if (event.key === "Enter") {
      selectHandler(event);
    }
  };

  const getOptions = () => ({
    closeOnItemSelect,
  });

  const selectHandler = (e: SyntheticEvent) => {
    if (!disabled) {
      onItemSelected(() => onSelect?.(e), getOptions());
    }
  };

  const handleFocus = () => {
    setItemFocused(true);
  };

  const handleBlur = () => {
    setItemFocused(false);
  };

  const conditionalStyles = () =>
    !noStyle && [
      styles.dropdownItem,
      styles.color[isColor],
      styles.textDirection[textDirection],
      (isFullWidth || noTruncation) && styles.noMaxWidth,
      isHighlighted && styles.highlighted,
      disabled && styles.disabled,
    ];

  const textStyles = () => {
    return [
      styles.truncateChild,
      (isFullWidth || noTruncation) && styles.noMaxWidth,
      showByMultiLine && styles.applyMultiLine,
    ];
  };

  const renderIcon = (location: string) => {
    if (location !== iconLocation) return;

    return (
      !hideSpacer &&
      icon && (
        <div data-testid="spacer" css={[styles.spacer.base, styles.spacer[iconLocation]]}>
          {icon && <FontAwesomeIcon icon={icon} />}
        </div>
      )
    );
  };

  return (
    <div
      css={conditionalStyles()}
      tabIndex={getTabIndex()}
      onKeyPress={keyPressHandler}
      onClick={clickHandler}
      onFocus={handleFocus}
      onBlur={handleBlur}
      role="menuitem"
      {...rest}
    >
      {renderIcon("start")}
      <div css={textStyles()}>{children}</div>
      {renderIcon("end")}
    </div>
  );
};

export { DropdownItem };
